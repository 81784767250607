import React from "react"
import Layout from "../components/Layout"
import Footer from "../components/Footer"
import { navigateTo } from "gatsby-link"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => navigateTo(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  render() {
    return (
      <Layout>
        <main>
          <section
            className="section section-md section-image overlay-dark"
            style={{ backgroundImage: `url("../hero-2.jpg")` }}
          >
            <div className="container">
              <div className="row mt-4 mt-lg-6 justify-content-center">
                <div className="col-md-10 col-lg-10">
                  <form
                    name="contact"
                    method="post"
                    action="/thanks/"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={this.handleSubmit}
                    className="card shadow-sm border-light p-2 p-md-3 p-lg-5"
                  >
                    <div className="card-header">
                      <h4>Got a question or proposal?</h4>
                      <p>
                        Cool! Let us know and we will get back to you in no
                        time.
                      </p>
                    </div>
                    <div className="card-body pt-0">
                      <div className="form-group">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="far fa-user"></i>
                            </span>
                          </div>
                          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                          <input
                            type="hidden"
                            name="form-name"
                            value="contact"
                          />
                          <p hidden>
                            <label>
                              Don’t fill this out:{" "}
                              <input
                                name="bot-field"
                                onChange={this.handleChange}
                              />
                            </label>
                          </p>
                          <input
                            placeholder="Your name"
                            required
                            className="form-control"
                            type="text"
                            name="name"
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="fas fa-at"></i>
                            </span>
                          </div>
                          <input
                            className="form-control"
                            placeholder="Email"
                            type="text"
                            name="email"
                            onChange={this.handleChange}
                            required
                          ></input>
                        </div>
                      </div>
                      <div className="form-group">
                        <textarea
                          className="form-control"
                          id="exampleTextarea2"
                          placeholder="How can we help?"
                          rows="3"
                          name="message"
                          onChange={this.handleChange}
                          required
                        ></textarea>
                      </div>
                    </div>
                    <div className="card-footer mx-4 px-0 pt-0">
                      <button
                        type="submit"
                        className="btn btn-block btn-primary animate-up-2"
                      >
                        Send message
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </main>

        <Footer></Footer>
      </Layout>
    )
  }
}
